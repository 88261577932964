import { AxiosInstance, AxiosResponse } from 'axios'
/* eslint-disable import/no-cycle */
import { BloomreachPostSalonDetailBody } from '../types/types'

const postSalonDetail = async (client: AxiosInstance, reqBody: BloomreachPostSalonDetailBody): Promise<void> => {
	try {
		await client.post<any, AxiosResponse<any>, BloomreachPostSalonDetailBody>('/api/tracking/web/view/salon/', reqBody)
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
	}
}

const loadApiEndpoints = (client: AxiosInstance) => {
	return {
		postSalonDetail: (reqBody: BloomreachPostSalonDetailBody) => postSalonDetail(client, reqBody)
	}
}

export default loadApiEndpoints

export type BloomreachApiEndpoints = ReturnType<typeof loadApiEndpoints>
