/* eslint-disable global-require */
import { IDropdownOption, Ratings, Toast, ToastModel } from '@notino/react-styleguide'
import dayjs, { Dayjs } from 'dayjs'
import { OverlayScrollbarsComponentProps } from 'overlayscrollbars-react'
import { IntlFormatters } from 'react-intl'
import qs from 'query-string'
import { jwtDecode } from 'jwt-decode'
import * as Sentry from '@sentry/react'
import React from 'react'
import axios, { AxiosError } from 'axios'
import { IToastProps } from '@notino/react-styleguide/dist/types/components/atoms/Toast'
import { toast, ToastContentProps } from 'react-toastify'

// types
// eslint-disable-next-line import/no-cycle
import {
	Phone,
	Price,
	SalonServicesResponse,
	ConfigResponse,
	Cosmetic,
	Language,
	GetSalonsFilterCountResponse,
	AvailableRsTimeSlotsResponse,
	SalonsCategoryPageServerQueryType,
	ServicesTopCategoryData,
	ServiceCategoryData,
	SalonPageQueryType,
	PartialRatingType,
	SalonsListPageServerQueryType,
	// AvailableLanguagesType,
	MyReservationPageQueryType,
	ReservationBookingModalInitData,
	HomePageServerQueryType,
	ShopId,
	CmsHeroData,
	HeroSectionData
} from '../types/types'
// import { PathsType } from '../types/pathType'
import { components } from '../types/b2c'
import { ICustomConfig } from './request'
import { CheckboxGroupOption } from '../formFields/CheckboxGroupFormField/types'

// enums
import {
	DAYNAME_DATE_FORMAT,
	PDF_FILE_TYPE,
	RESERVATIONS_TIME_SLOTS,
	SALONS_FILTER_TYPE,
	SORTING_OPTION,
	DAYNAME_DATE_YEAR_FORMAT,
	OPENING_HOURS_STATUS,
	RATINGS,
	ARRAY_QUERY_PARAM_MAX_LENGTH,
	PARTIAL_RATING,
	TOKEN_AUD,
	GOOGLE_MAPS_URL,
	MSG_TYPE,
	ErrorMessage
} from './enums'

// regex
import { uuidRegex } from './regex'

// pats
import Paths from '../routes/paths'

// utils
import { getIntl } from './intl'

const getCountryByCode = (countries: ConfigResponse['rolloutCountries'], code: string): ConfigResponse['rolloutCountries'][0] | undefined => {
	return countries.find((country) => country.code === code)
}

/**
 * Finds the corresponding value in an errors map for a given error key.
 * @template T - The type of the errors map.
 * @param errorKey - The key from the error message, potentially with indices.
 * @param errorsMap - The mapping of error paths to form fields.
 * @returns The mapped form field or `undefined` if no match is found.
 */
export const findErrorKeyValue = <T extends Record<string, any>>(errorKey: string, errorsMap: T): T[keyof T] | undefined => {
	// Return the correct type based on the map's value type
	// Transform the errorKey to replace numbers with '[number]'
	const transformedKey = errorKey.replace(/\[\d+\]/g, '[number]')

	// Return the value or undefined if not found
	return errorsMap[transformedKey]
}

export const ERROR_MESSAGES = () => {
	const intl = getIntl()

	return {
		GENERAL_ERROR: intl.formatMessage({
			id: 'Something went wrong. Please try again or contact our support.',
			defaultMessage: 'Something went wrong. Please try again or contact our support.'
		}),
		UNAUTHORIZED_MSG: intl.formatMessage({
			id: 'Unauthorized. You must be logged in to perform this action.',
			defaultMessage: 'Unauthorized. You must be logged in to perform this action.'
		}),
		GENERAL_FORM_ERROR_MSG: intl.formatMessage({
			id: 'Something went wrong. Please check the data in the form or try again.',
			defaultMessage: 'Something went wrong. Please check the data in the form or try again.'
		}),
		SERVER_ERROR: intl.formatMessage({
			id: 'Server connection error.',
			defaultMessage: 'Server connection error.'
		})
	}
}

/**
 * Get phone number
 * @param phone IPhone
 * @returns {string}
 */
export const getPhoneNumber = (phone?: Phone, countries?: ConfigResponse['rolloutCountries']): string => {
	if (!phone || !countries || countries?.length < 0) {
		return ''
	}
	const country = getCountryByCode(countries, phone.phonePrefixCountryCode)
	const result = `${country?.phonePrefix}${phone.phone}`
	return result
}

/**
 * Get first level categories
 * @param {categories} ISalonServicesResponse
 * @returns {string[]}
 */
export const getFirstLevelCategoriesNames = (categories?: SalonServicesResponse): string[] => {
	if (!categories) {
		return []
	}
	const result: string[] = []
	categories?.groupedServicesByCategory?.forEach((obj) => obj.category?.name && result.push(obj.category?.name))
	return result
}

export const repeat = (textToRepeat: string, numberOfTimesToRepeat: number): string => {
	let result = ''
	for (let i = 0; i < numberOfTimesToRepeat; i += 1) {
		result = `${result}${textToRepeat}`
	}
	return result
}

/**
 * Transforms normalized form to number
 * @param {Price | null} [price]
 * @returns {number}
 */
export const decodePrice = (price: Price | null | undefined): number | null | undefined => {
	if (!price) {
		return null
	}

	const { exponent, significand } = price

	// Calculate the decimal places from the exponent
	const decimalPlaces = Math.abs(exponent)

	// Calculate the result
	const result = significand * 10 ** exponent

	// Use toFixed() to format the number with the calculated decimal places
	const formattedResult = result.toFixed(decimalPlaces)

	// Convert the formatted result back to a number
	const numberResult = parseFloat(formattedResult)

	return numberResult
}

/**
 * Check if is pdf file or not
 * @param {fileUrl | null | undefined} [string]
 * @returns {string | undefined }
 */
export const isFilePDF = (fileUrl?: string): string | undefined => {
	if (!fileUrl) {
		return undefined
	}

	return fileUrl.endsWith('.pdf') ? PDF_FILE_TYPE : undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeQueryParams = (queryParams: any) =>
	Object.keys(queryParams)?.map((queryParam) => {
		if (queryParam === '' || queryParam === undefined || queryParam === null) {
			return undefined
		}
		return queryParam
	})

export const getCategoryIDs = (subCategoriesIDs: string[], topLevelCategoryID: string | undefined) => {
	let categoryIDs: string[] | undefined
	if (subCategoriesIDs && subCategoriesIDs.length > 0) {
		categoryIDs = subCategoriesIDs
	} else if (topLevelCategoryID) {
		categoryIDs = [topLevelCategoryID]
	}
	return categoryIDs
}

export const isElementOverflownX = ({ clientWidth, scrollWidth }: { clientWidth: number; scrollWidth: number }) => {
	return scrollWidth > clientWidth
}

export const toHoursAndMinutes = (totalMinutes?: number) => {
	if (!totalMinutes) {
		return ''
	}
	const hours = Math.floor(totalMinutes / 60)
	const minutes = totalMinutes % 60
	return `${hours > 0 ? `${hours}h` : ''}${minutes > 0 ? ` ${minutes}m` : ''}`
}

export const getGoogleMapsLink = ({ lat, lon }: { lat: number | undefined; lon: number | undefined }) => {
	return lat && lon ? `${GOOGLE_MAPS_URL}/?q=${lat},${lon}` : undefined
}

export const formatNumberValueOnChange = (value: string) => {
	return value.replace(/[^0-9,.]/g, '')
}

export const formatNumber = (value?: string | number) => {
	if (Number.isNaN(value)) return '' // Handle empty input

	let formattedValue = String(value)

	// Remove leading zero numbers, except when it's just a single zero
	if (formattedValue !== '0' && !/^0[.,]/.test(formattedValue)) {
		formattedValue = formattedValue.replace(/^0+/, '')
	}

	// Remove any non-numeric characters
	formattedValue = formattedValue.replace(/[^0-9,.]/g, '')

	// Replace periods with commas for the decimal separator
	formattedValue = formattedValue.replace(/\./g, ',')

	// Split the value into integer and decimal parts
	const [integerPart, decimalPart] = formattedValue.split(',')

	// Add thousands separators to the integer part
	const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	// Ensure the decimal part has at most two decimal places
	const formattedDecimalPart = decimalPart ? `,${decimalPart.slice(0, 2)}` : ''

	return formattedIntegerPart + formattedDecimalPart
}

export const parseTextValueToNumberValue = (formattedValue: string) => {
	if (!formattedValue) return NaN // Handle empty input

	// Remove any non-numeric characters, except for periods
	const numericString = formattedValue.replace(/[^0-9]/g, '')

	// Use parseFloat to convert the string to a floating-point number
	return parseFloat(numericString)
}

export const TIME_SLOTS_TRANSLATIONS = (): Record<RESERVATIONS_TIME_SLOTS, string> => {
	const intl = getIntl()

	return {
		[RESERVATIONS_TIME_SLOTS.ANY]: intl.formatMessage({ id: 'Anytime', defaultMessage: 'Anytime' }),
		[RESERVATIONS_TIME_SLOTS.MORNING]: intl.formatMessage({ id: 'Morning', defaultMessage: 'Morning' }),
		[RESERVATIONS_TIME_SLOTS.AFTERNOON]: intl.formatMessage({ id: 'Afternoon', defaultMessage: 'Afternoon' }),
		[RESERVATIONS_TIME_SLOTS.EVENING]: intl.formatMessage({ id: 'Evening', defaultMessage: 'Evening' })
	}
}

export const SALONS_FILTER_ITEMS_CONFIG = (priceMin: number | undefined, priceMax: number | undefined) => {
	const { formatMessage } = getIntl()

	return {
		[SALONS_FILTER_TYPE.PRICE]: {
			defaultValue: [priceMin, priceMax],
			title: formatMessage({ id: 'Price' }),
			getSelectedTitle: (
				currencySymbol: string | undefined,
				minPrice: number | undefined,
				maxPrice: number | undefined,
				priceFrom: number | undefined,
				priceTo: number | undefined
			) => {
				const missingBoundaryRanges = minPrice === undefined || maxPrice === undefined
				const missingSelectedPrices = Number.isNaN(priceFrom) || Number.isNaN(priceTo)
				const notChangedRanges = minPrice === priceFrom && maxPrice === priceTo

				if (missingBoundaryRanges || notChangedRanges || missingSelectedPrices || !currencySymbol) {
					return null
				}

				if (minPrice === priceFrom && maxPrice !== priceTo) {
					return formatMessage({ id: 'to { priceTo }', defaultMessage: 'to { priceTo }' }, { priceTo: `${formatNumber(priceTo)}${currencySymbol}` })
				}

				if (maxPrice === priceTo && minPrice !== priceFrom) {
					return formatMessage(
						{ id: 'from { priceFrom }', defaultMessage: 'from { priceFrom }' },
						{ priceFrom: `${formatNumber(priceFrom)}${currencySymbol}` }
					)
				}

				return formatMessage(
					{ id: 'from { priceFrom } to { priceTo }', defaultMessage: 'from { priceFrom } to { priceTo }' },
					{ priceFrom: `${formatNumber(priceFrom)}${currencySymbol}`, priceTo: `${formatNumber(priceTo)}${currencySymbol}` }
				)
			}
		},
		[SALONS_FILTER_TYPE.AVAILABLE_RS]: {
			defaultValue: false,
			title: formatMessage({ id: 'Available for online reservations', defaultMessage: 'Available for online reservations' }),
			getSelectedTitle: (available?: boolean) => {
				return available ? formatMessage({ id: 'Available for online reservations', defaultMessage: 'Available for online reservations' }) : null
			}
		},
		[SALONS_FILTER_TYPE.OPENING_HOURS]: {
			defaultValue: false,
			title: formatMessage({ id: 'Is open', defaultMessage: 'Is open' }),
			getSelectedTitle: (open?: boolean) => {
				return open ? formatMessage({ id: 'Is open', defaultMessage: 'Is open' }) : null
			}
		},
		[SALONS_FILTER_TYPE.RATING]: {
			defaultValue: [],
			title: formatMessage({ id: 'Rating', defaultMessage: 'Rating' }),
			getSelectedTitle: (selectedRatings?: number[]) => {
				if (!selectedRatings?.length) {
					return null
				}
				const formattedRatings = selectedRatings.map((rating) => `${rating}*`)
				return `${formatMessage({ id: 'Rating', defaultMessage: 'Rating' })} ${formattedRatings.join(', ')}`
			}
		},
		[SALONS_FILTER_TYPE.COSMETICS]: {
			defaultValue: [],
			title: formatMessage({ id: 'Cosmetics they use', defaultMessage: 'Cosmetics they use' }),
			getSelectedTitle: (
				cosmetics: Cosmetic[],
				selectedCosmetics: string[]
			): {
				label: string | null
				count: number
			} => {
				if (selectedCosmetics.length === 0) {
					return { label: null, count: 0 }
				}

				const defaultLabel = formatMessage({ id: 'Cosmetics', defaultMessage: 'Cosmetics' })

				if (selectedCosmetics.length === 1) {
					return { label: cosmetics.find((cosmetic) => cosmetic.id === selectedCosmetics[0])?.name || defaultLabel, count: 0 }
				}

				const validCosmetics = selectedCosmetics.filter((selectedCosmeticId) => cosmetics.find((cosmetic) => cosmetic.id === selectedCosmeticId))

				return {
					label: defaultLabel,
					count: validCosmetics.length
				}
			}
		},
		[SALONS_FILTER_TYPE.DATE]: {
			defaultValue: {
				avResTimeSlotDayPart: RESERVATIONS_TIME_SLOTS.ANY,
				avResTimeSlotDateFrom: undefined,
				avResTimeSlotDateTo: undefined
			},
			title: formatMessage({ id: 'Date', defaultMessage: 'Date' }),
			getSelectedTitle: (avResTimeSlotDayPart: RESERVATIONS_TIME_SLOTS, avResTimeSlotDateFrom?: Dayjs, avResTimeSlotDateTo?: Dayjs) => {
				const timeSlotTranslation = avResTimeSlotDayPart !== RESERVATIONS_TIME_SLOTS.ANY ? TIME_SLOTS_TRANSLATIONS()[avResTimeSlotDayPart] || '' : ''

				if (!avResTimeSlotDateFrom && !avResTimeSlotDateTo) {
					return timeSlotTranslation
				}

				const formattedPeriod = timeSlotTranslation ? `, ${timeSlotTranslation}` : ''

				let formatFrom = DAYNAME_DATE_FORMAT

				if (avResTimeSlotDateFrom && !avResTimeSlotDateTo) {
					if (avResTimeSlotDateFrom.isSame(dayjs(), 'date')) {
						return `${formatMessage({ id: 'Today', defaultMessage: 'Today' })}${formattedPeriod}`
					}

					if (avResTimeSlotDateFrom.year() !== dayjs().year()) {
						formatFrom = DAYNAME_DATE_YEAR_FORMAT
					}
					return `${dayjs(avResTimeSlotDateFrom).format(formatFrom)}${formattedPeriod}`
				}

				if (avResTimeSlotDateTo) {
					let formatTo = DAYNAME_DATE_FORMAT

					if (avResTimeSlotDateTo.year() !== dayjs().year()) {
						formatTo = DAYNAME_DATE_YEAR_FORMAT
					}

					return `${dayjs(avResTimeSlotDateFrom).format(formatFrom)} - ${dayjs(avResTimeSlotDateTo).format(formatTo)}${formattedPeriod}`
				}
				return ''
			}
		},
		[SALONS_FILTER_TYPE.LANGUAGES]: {
			defaultValue: [],
			title: formatMessage({ id: 'Languages they speak', defaultMessage: 'Languages they speak' }),
			getSelectedTitle: (
				languages: Language[],
				selectedLanguages: string[]
			): {
				label: string | null
				count: number
			} => {
				if (selectedLanguages.length === 0) {
					return { label: null, count: 0 }
				}

				const defaultLabel = formatMessage({ id: 'Language', defaultMessage: 'Language' })

				if (selectedLanguages.length === 1) {
					return { label: languages.find((lang) => lang.id === selectedLanguages[0])?.name || defaultLabel, count: 1 }
				}

				const validLanguages = selectedLanguages.filter((selectedLanguageId) => languages.find((language) => language.id === selectedLanguageId))

				return { label: defaultLabel, count: validLanguages.length }
			}
		},
		[SALONS_FILTER_TYPE.SORTING]: {
			defaultValue: SORTING_OPTION.RECOMMENDED,
			title: formatMessage({ id: 'Sorting', defaultMessage: 'Sorting' }),
			getSelectedTitle: (sortingOptions: IDropdownOption[], currentOption: SORTING_OPTION, defaultOption = SORTING_OPTION.RECOMMENDED) => {
				if (currentOption === defaultOption) {
					return null
				}

				return (
					sortingOptions.find((option) => {
						return option.id === currentOption
					})?.label || null
				)
			}
		}
	}
}

// compares for equality only values, order of the elements doesn't matter
export const areValuesInArrayEqual = <T,>(array1: T[], array2: T[]) => {
	if (array1.length !== array2.length) {
		return false
	}

	const sortedA = [...array1].sort()
	const sortedB = [...array2].sort()

	return sortedA.every((value, index) => value === sortedB[index])
}

export const isNil = <T,>(value: T): boolean => {
	return value === undefined || value === null
}

export const SCROLLBAR_OPTIONS: OverlayScrollbarsComponentProps['options'] = {
	scrollbars: {
		autoHide: 'move',
		autoHideDelay: 1000
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEnumValue = <T extends { [k: string]: string | number }>(checkValue: any, enumObject: T): checkValue is T[keyof T] =>
	(typeof checkValue === 'string' || typeof checkValue === 'number') && Object.values(enumObject).includes(checkValue)

// TODO: tieto validacie by sa dali prerobit cez ZOD
export const getValidHomePageQuery = (query: HomePageServerQueryType) => {
	return {
		...query,
		latMy: typeof query.latMy !== 'undefined' && typeof query.latMy !== null && !Number.isNaN(query.latMy) ? query.latMy : undefined,
		lonMy: typeof query.lonMy !== 'undefined' && typeof query.lonMy !== null && !Number.isNaN(query.lonMy) ? query.lonMy : undefined
	}
}

export const getValidSalonsPageQuery = (query: SalonsCategoryPageServerQueryType) => {
	const validAvResTimeSlotDateFrom = query.avResTimeSlotDateFrom && dayjs(query.avResTimeSlotDateFrom).isValid() ? query.avResTimeSlotDateFrom : undefined
	const validAvResTimeSlotDateTo =
		validAvResTimeSlotDateFrom &&
		query.avResTimeSlotDateTo &&
		dayjs(query.avResTimeSlotDateTo).isValid() &&
		dayjs(query.avResTimeSlotDateTo).isAfter(dayjs(validAvResTimeSlotDateFrom))
			? query.avResTimeSlotDateTo
			: undefined
	const validAvResTimeSlotDayPart = isEnumValue(query.avResTimeSlotDayPart, RESERVATIONS_TIME_SLOTS) ? query.avResTimeSlotDayPart : undefined

	const validServiceTotalPriceFrom =
		query.serviceTotalPriceFrom !== undefined && query.avResTimeSlotDateFrom !== null && !Number.isNaN(query.serviceTotalPriceFrom)
			? query.serviceTotalPriceFrom
			: undefined

	let validServiceTotalPriceTo

	if (query.serviceTotalPriceTo !== undefined && query.serviceTotalPriceTo !== null && !Number.isNaN(query.serviceTotalPriceTo)) {
		if (validServiceTotalPriceFrom !== undefined && validServiceTotalPriceFrom < query.serviceTotalPriceTo) {
			validServiceTotalPriceTo = query.serviceTotalPriceTo
		} else if (validServiceTotalPriceFrom === undefined) {
			validServiceTotalPriceTo = query.serviceTotalPriceTo
		}
	}

	return {
		...query,
		page: query.page !== undefined && query.page !== null && !Number.isNaN(query.page) ? query.page : 1,
		openingHoursStatus: isEnumValue(query.openingHoursStatus, OPENING_HOURS_STATUS) ? query.openingHoursStatus : undefined,
		latMy: typeof query.latMy !== 'undefined' && typeof query.latMy !== null && !Number.isNaN(query.latMy) ? query.latMy : undefined,
		lonMy: typeof query.lonMy !== 'undefined' && typeof query.lonMy !== null && !Number.isNaN(query.lonMy) ? query.lonMy : undefined,
		googlePlaceID: typeof query.googlePlaceID === 'string' ? query.googlePlaceID : undefined,
		categoryIDs: Array.isArray(query.categoryIDs) ? query.categoryIDs.filter((item) => item.match(uuidRegex)) : [],
		languageIDs: Array.isArray(query.languageIDs) ? query.languageIDs.filter((item) => item.match(uuidRegex)).slice(0, ARRAY_QUERY_PARAM_MAX_LENGTH) : [], // limit max number of selected items
		cosmeticIDs: Array.isArray(query.cosmeticIDs) ? query.cosmeticIDs.filter((item) => item.match(uuidRegex)).slice(0, ARRAY_QUERY_PARAM_MAX_LENGTH) : [], // limit max number of selected items
		orderBy: isEnumValue(query.orderBy, SORTING_OPTION) ? query.orderBy : SORTING_OPTION.RECOMMENDED,
		exactRating: Array.isArray(query.exactRating) ? query.exactRating.filter((item) => isEnumValue(item, RATINGS)) : [],
		isMapView: typeof query.isMapView === 'boolean' ? query.isMapView : false,
		serviceTotalPriceFrom: validServiceTotalPriceFrom,
		serviceTotalPriceTo: validServiceTotalPriceTo,
		// NOTE: hasAvailableReservationSystem is by default true
		hasAvailableReservationSystem: typeof query.hasAvailableReservationSystem === 'boolean' ? query.hasAvailableReservationSystem : true,
		avResTimeSlotDayPart: validAvResTimeSlotDayPart,
		avResTimeSlotDateFrom: validAvResTimeSlotDateFrom,
		avResTimeSlotDateTo: validAvResTimeSlotDateTo
	}
}

type ReviewTokenJwtPayload = {
	calendarEventID?: string
	customerID?: string
	salonID?: string
	iat?: number
	exp?: number
	aud?: string
}

export const getValidReviewToken = (token: string | undefined | null, calendarEventID: string | undefined | null) => {
	if (!token || typeof token !== 'string') {
		return undefined
	}

	let validToken: string | undefined | null = token

	try {
		const decodedToken = jwtDecode<ReviewTokenJwtPayload>(validToken)
		if (!decodedToken.exp || dayjs.unix(decodedToken.exp) < dayjs()) {
			// Token has expired
			validToken = undefined
		} else if (decodedToken.aud !== TOKEN_AUD.REVIEW_RESERVATION) {
			// Token has unsupported audience
			validToken = undefined
		} else if (decodedToken.calendarEventID !== calendarEventID) {
			// calendarEventId from token is not the same as from calendarEventID query parameter
			validToken = undefined
		}
	} catch (e) {
		Sentry.captureException(e)
		// eslint-disable-next-line no-console
		console.error(e)
		validToken = undefined
	}

	return validToken
}

export const getValidSalonPageQuery = (query: SalonPageQueryType) => {
	return {
		...query,
		t: getValidReviewToken(query.t, query.calendarEventID),
		selectedAvResTimeSlotDayPart: isEnumValue(query.selectedAvResTimeSlotDayPart, RESERVATIONS_TIME_SLOTS) ? query.selectedAvResTimeSlotDayPart : undefined,
		selectedAvResTimeSlotDateFrom: dayjs(query.selectedAvResTimeSlotDateFrom).isValid() ? query.selectedAvResTimeSlotDateFrom : undefined
	}
}

export const getValidPriceRangeQueryParams = (
	serviceTotalPriceFrom: SalonsCategoryPageServerQueryType['serviceTotalPriceFrom'],
	serviceTotalPriceTo: SalonsCategoryPageServerQueryType['serviceTotalPriceTo'],
	priceRange?: GetSalonsFilterCountResponse['priceRange']
) => {
	let validServiceTotalPriceFrom: number | undefined

	if (serviceTotalPriceFrom !== undefined && serviceTotalPriceFrom !== null && !Number.isNaN(serviceTotalPriceFrom)) {
		if (priceRange) {
			if (serviceTotalPriceFrom >= priceRange.minValue && serviceTotalPriceFrom < priceRange.maxValue) {
				validServiceTotalPriceFrom = serviceTotalPriceFrom
			} else {
				validServiceTotalPriceFrom = priceRange.minValue
			}
		} else {
			validServiceTotalPriceFrom = serviceTotalPriceFrom
		}
	}

	let validServiceTotalPriceTo: number | undefined

	if (serviceTotalPriceTo !== undefined && serviceTotalPriceTo !== null && !Number.isNaN(serviceTotalPriceTo)) {
		if (priceRange) {
			if (validServiceTotalPriceFrom !== undefined) {
				if (serviceTotalPriceTo > validServiceTotalPriceFrom && serviceTotalPriceTo <= priceRange.maxValue) {
					validServiceTotalPriceTo = serviceTotalPriceTo
				} else {
					validServiceTotalPriceTo = priceRange.maxValue
				}
			} else if (serviceTotalPriceTo <= priceRange.maxValue && serviceTotalPriceTo > priceRange.minValue) {
				validServiceTotalPriceTo = serviceTotalPriceTo
			} else {
				validServiceTotalPriceTo = priceRange.maxValue
			}
		} else if (validServiceTotalPriceFrom !== undefined && validServiceTotalPriceFrom < serviceTotalPriceTo) {
			validServiceTotalPriceTo = serviceTotalPriceTo
		} else if (validServiceTotalPriceFrom === undefined) {
			validServiceTotalPriceTo = serviceTotalPriceTo
		}
	}

	return {
		serviceTotalPriceFrom: validServiceTotalPriceFrom,
		serviceTotalPriceTo: validServiceTotalPriceTo
	}
}

export const getValidMyReservationPageQuery = (query: MyReservationPageQueryType): MyReservationPageQueryType => {
	return {
		...query,
		t: query.t
	}
}

export const isTimeSlotAvailable = (timeSlotData: AvailableRsTimeSlotsResponse['dates'], date: Dayjs) => {
	return timeSlotData.find((data) => dayjs(data.date).isSame(date, 'date'))?.available
}

export const DATE_PICKER_INIT_DATE = dayjs()

/**
 * NOTE: edit of url slug according to SEO feedback:
 * "The URL does not end with a slash, it is not an error, but it is not consistent throughout the notino website.
 * A URL without a trailing slash should be redirected to a variant with a trailing slash, and the canonical and the link from the home page should be corrected accordingly."
 */
export const formatUrlSlug = (slug: string | null | undefined) => {
	if (!slug) {
		return ''
	}

	return slug.endsWith('/') ? slug : `${slug}/`
}

export const truncateString = (inputString: string | null | undefined, maxLength = 150): string => {
	if (!inputString) {
		return ''
	}

	if (inputString.length <= maxLength) {
		return inputString
	}

	const truncatedString = inputString.substring(0, maxLength - 3) // -3 to account for the three dots
	return `${truncatedString}...`
}

export const findSmallestIndexMatch = (first: string[], second: string[]): string | undefined => {
	let smallestIndex = Infinity
	let smallestValue: string | undefined

	first.forEach((value) => {
		const index = second.indexOf(value)
		if (index !== -1 && index < smallestIndex) {
			smallestIndex = index
			smallestValue = value
		}
	})

	return smallestValue
}

export const RESERVATIONS_TIME_SLOTS_START_TIME: Record<Exclude<RESERVATIONS_TIME_SLOTS, 'ANY'>, number> & Record<RESERVATIONS_TIME_SLOTS.ANY, undefined> = {
	[RESERVATIONS_TIME_SLOTS.ANY]: undefined,
	[RESERVATIONS_TIME_SLOTS.MORNING]: 0,
	[RESERVATIONS_TIME_SLOTS.AFTERNOON]: 12,
	[RESERVATIONS_TIME_SLOTS.EVENING]: 18
}

/**
 * Remove accent and transform to lower case
 * Usefull for searching on FE
 * @link https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
 */
export const transformToLowerCaseWithoutAccent = (source?: string): string =>
	source
		? source
				.toLowerCase()
				.normalize('NFD')
				.replace(/\p{Diacritic}/gu, '')
		: ''

export const transformQueryForLink = <T extends object = {}>(query: T) => {
	return Object.fromEntries(
		Object.entries(query).map(([key, value]) => {
			if (typeof value === 'boolean') {
				return [key, value ? 1 : 0]
			}
			return [key, value]
		})
	) as T
}

// type NestedPathsKeys<T> = T extends object ? keyof T : never
// type PageLink = Record<NestedPathsKeys<PathsType[AvailableLanguagesType]>, Function>

export const PAGE_LINKS /*: Record<NestedPathsKeys<PathsType[AvailableLanguagesType]>, Function> */ = {
	'/salons/homepage': (locale: string, queryParams?: Partial<HomePageServerQueryType>) => {
		const search = queryParams ? qs.stringify(transformQueryForLink(queryParams), { arrayFormat: 'none', skipNull: true }) : ''

		const href = `${Paths[locale as keyof typeof Paths]['/salons/homepage']}${search ? `?${search}` : ''}`
		return href
	},
	'/salons': (locale: string, queryParams?: Partial<SalonsListPageServerQueryType>) => {
		const search = queryParams ? qs.stringify(transformQueryForLink(queryParams), { arrayFormat: 'none', skipNull: true }) : ''

		const href = `${Paths[locale as keyof typeof Paths]['/salons']}${search ? `?${search}` : ''}`
		return href
	},
	'/salons/services': (locale: string) => Paths[locale as keyof typeof Paths]['/salons/services'],
	'/salons/services/:categorySlug': (locale: string, slugName: string, queryParams?: Partial<SalonsCategoryPageServerQueryType>) => {
		const search = queryParams ? qs.stringify(transformQueryForLink(queryParams), { arrayFormat: 'none', skipNull: true }) : ''

		const href = `${Paths[locale as keyof typeof Paths]['/salons/services']}${formatUrlSlug(slugName)}${search ? `?${search}` : ''}`
		return href
	},
	'/salons/:salonSlug': (
		locale: string,
		slugName: string,
		dataForQuery?: {
			calendarEventID?: string
			currentCategoryID?: string
			avResTimeSlotDayPart?: RESERVATIONS_TIME_SLOTS
			avResTimeSlotDateFrom?: string
		}
	) => {
		const { currentCategoryID, avResTimeSlotDayPart, avResTimeSlotDateFrom, calendarEventID } = dataForQuery || {}

		const salonPageQueryParams: Omit<SalonPageQueryType, 't'> = {
			calendarEventID,
			selectedIndustryID: currentCategoryID,
			selectedAvResTimeSlotDayPart: avResTimeSlotDayPart,
			selectedAvResTimeSlotDateFrom: avResTimeSlotDateFrom
		}

		const search = qs.stringify(salonPageQueryParams, { skipNull: true })

		const itemHref = `${Paths[locale as keyof typeof Paths]['/salons']}${formatUrlSlug(slugName)}`
		return search ? `${itemHref}?${search}` : itemHref
	},
	'/salons/my-reservations': (locale: string) => {
		const href = `${Paths[locale as keyof typeof Paths]['/salons/my-reservations']}`
		return href
	},
	'/salons/my-reservations/:reservationID': (locale: string, reservationID: string) => {
		const href = `${Paths[locale as keyof typeof Paths]['/salons/my-reservations']}${formatUrlSlug(reservationID)}`
		return href
	},
	'/salons/auth-complete': (locale: string) => {
		const href = `${Paths[locale as keyof typeof Paths]['/salons/auth-complete']}`
		return href
	}
}

export const PARTIAL_RATINGS_TRANSLATIONS = (): Record<PartialRatingType, string> => {
	const intl = getIntl()

	return {
		[PARTIAL_RATING.SERVICE]: intl.formatMessage({ id: 'Services', defaultMessage: 'Services' }),
		[PARTIAL_RATING.PLACE]: intl.formatMessage({ id: 'Environment', defaultMessage: 'Environment' }),
		[PARTIAL_RATING.COMMUNICATION]: intl.formatMessage({ id: 'Communication', defaultMessage: 'Communication' }),
		[PARTIAL_RATING.GENERAL_IMPRESSION]: intl.formatMessage({ id: 'Overall Impression', defaultMessage: 'Overall Impression' })
	}
}

export const PARTIAL_RATING_OPTIONS = () => {
	const ratingTranslations = PARTIAL_RATINGS_TRANSLATIONS()

	return [
		{ value: PARTIAL_RATING.SERVICE, label: ratingTranslations[PARTIAL_RATING.SERVICE] },
		{ value: PARTIAL_RATING.PLACE, label: ratingTranslations[PARTIAL_RATING.PLACE] },
		{
			value: PARTIAL_RATING.COMMUNICATION,
			label: ratingTranslations[PARTIAL_RATING.COMMUNICATION]
		},
		{
			value: PARTIAL_RATING.GENERAL_IMPRESSION,
			label: ratingTranslations[PARTIAL_RATING.GENERAL_IMPRESSION]
		}
	]
}

export const BOOKING_FORM_Z_INDEXES = {
	BOOKING_FORM: 9,
	DROPDOWN: 10
}

export const getDateTime = (date: string, time: string) => {
	return dayjs(`${date} ${time}`, 'YYYY-MM-DD HH:mm')
}

export const getEmployeeName = (employee: { firstName?: string; lastName?: string; email?: string }) => {
	let employeeName = ''

	if (employee.firstName && employee.lastName) {
		employeeName = `${employee.firstName} ${employee.lastName}`
	} else if (employee.firstName) {
		employeeName = employee.firstName
	} else if (employee.lastName) {
		employeeName = employee.lastName
	} else if (employee.email) {
		employeeName = employee.email
	}

	return employeeName
}

export const formatReservationDateRange = (dateFormatter: IntlFormatters['formatDate'], startDate: Date, endDate: Date) => {
	const sameDay = dayjs(startDate).isSame(endDate, 'date')

	const formatDate = (date: Date) =>
		dateFormatter(date, {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		})

	const formatTime = (date: Date) =>
		dateFormatter(date, {
			hour: 'numeric',
			minute: 'numeric'
		})

	if (sameDay) {
		return `${formatDate(startDate)} - ${formatTime(endDate)}`
	}
	return `${formatDate(startDate)} - ${formatDate(endDate)}`
}

export const RESERVATION_BOOKING_MODAL_DEFAULT_INIT_DATA: ReservationBookingModalInitData = {
	initIndustryID: null,
	initServiceID: null,
	initAvResTimeSlotDayPart: null,
	initServiceCategoryParameterID: null,
	parameterSelectionData: null,
	employeeAndDateSelectionData: null,
	bookingConfirmationData: null
}

/**
 * @param groupedServicesByCategory
 * @param serviceId
 * @returns true if salon services has multiple industries with the same service
 */
export const hasMultipleIndustriesSameService = (
	groupedServicesByCategory: NonNullable<SalonServicesResponse['groupedServicesByCategory']>,
	serviceId: string
): boolean => {
	const categoriesWithService: Set<string> = new Set()

	// Use some to short-circuit the loop when a duplicate is found
	groupedServicesByCategory.some((group) => {
		const firstLevelCategory = group.category

		// Check the second-level categories
		return firstLevelCategory?.children.some((secondLevelChild) => {
			const secondLevelCategory = secondLevelChild.category

			// Check the third-level categories
			return secondLevelCategory?.children.some((thirdLevelChild) => {
				const { service } = thirdLevelChild

				if (service && service.id === serviceId) {
					categoriesWithService.add(firstLevelCategory.id)
				}

				// If more than one first-level category contains the service, return true to stop iteration
				return categoriesWithService.size > 1
			})
		})
	})

	return categoriesWithService.size > 1
}

type Category = NonNullable<NonNullable<SalonServicesResponse['groupedServicesByCategory']>>[number]

export const findServiceCategoryById = (
	categoryList: Category[],
	serviceId: string
): { industryData: ServicesTopCategoryData | null; serviceCategoryData: ServiceCategoryData | null } => {
	let foundServiceCategory: ServiceCategoryData | null = null
	let foundIndustry: ServicesTopCategoryData | null = null

	const searchInCategories = (categories: Category[]): void => {
		categories.forEach((category) => {
			const currentCategory = category.category

			if (currentCategory?.children) {
				currentCategory.children.forEach((childCategoryWrapper) => {
					const childCategory = childCategoryWrapper.category
					if (childCategory?.children) {
						childCategory.children.forEach((subChild) => {
							if (subChild.service?.id === serviceId) {
								foundServiceCategory = subChild
								foundIndustry = currentCategory
							}
						})

						// Recursive search for deeper nested categories
						if (!foundServiceCategory && childCategoryWrapper.category) {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							searchInCategories([{ category: childCategoryWrapper.category as any }])
						}
					}
				})
			}
		})
	}
	searchInCategories(categoryList)
	return { industryData: foundIndustry, serviceCategoryData: foundServiceCategory }
}

export const areReservationsAllowed = (config: ConfigResponse['allowReservations'], shopID: ShopId) => {
	return config[shopID] === true
}

const RATING_VALUES = Object.values(RATINGS).filter((value) => typeof value === 'number') as number[]

export const EXACT_RATING_OPTIONS = (
	ratingCounts: GetSalonsFilterCountResponse['exactRating'] | undefined,
	selectedRating: number[]
): CheckboxGroupOption<number>[] => {
	return RATING_VALUES.reduce((acc, ratingValue) => {
		// hide ratings with 0 count (except selected)
		// if ratingCounts is undefined => show all ratings, but without counts
		if (ratingCounts && !ratingCounts[ratingValue] && !selectedRating.includes(ratingValue)) {
			return acc
		}

		const value = Number(ratingValue)

		return [
			...acc,
			{
				value,
				label: <Ratings foreground={'background.inverse'} ratingId={`rating-${value}`} rating={value} />,
				description: ratingCounts ? formatNumber(ratingCounts[value] || 0) : undefined
			}
		]
	}, [] as CheckboxGroupOption<number>[])
}

type NotifData = {
	type: NonNullable<IToastProps['type']>
	icon?: IToastProps['icon']
	message: React.ReactNode
}

const CustomToast = ({ closeToast, data }: ToastContentProps<NotifData>) => {
	return <Toast type={ToastModel.ToastType.Error} content={data.message as JSX.Element} icon={data.icon} onClose={() => closeToast()} closeIcon={true} />
}

export const showNotifications = (messages: ErrorMessage[]) => {
	if (messages.length > 0) {
		messages.forEach((message) => {
			let notifData: NotifData = {
				type: ToastModel.ToastType.Info,
				message: message.message
			}
			const type = message.type.toLowerCase()
			switch (type) {
				// NOTE: warning and success types are not used in the project
				case MSG_TYPE.ERROR:
					notifData = { ...notifData, type: ToastModel.ToastType.Error, icon: 'IconSolidInfo' }
					break
				case MSG_TYPE.INFO:
					notifData = { ...notifData, type: ToastModel.ToastType.Info, icon: 'IconSolidInfo' }
					break
				default:
					break
			}

			toast<NotifData>(CustomToast, {
				data: notifData,
				autoClose: 5000
			})
		})
	}
}

type BasicAxiosError = {
	// B2C BE sends path value for error messages, but it is not present in the schema
	messages?: (components['schemas']['MessagesResponseSchema'][number] & { path?: string })[]
}

export const showErrorNotifications = (error: unknown) => {
	if (axios.isAxiosError(error)) {
		const axiosError = error as Omit<AxiosError<BasicAxiosError>, 'config'> & { config: ICustomConfig }

		const { displayNotification } = axiosError.config

		if (!displayNotification) {
			return
		}

		if (axiosError.response?.status === 504 || axiosError.message === 'Network Error') {
			showNotifications([
				{
					type: MSG_TYPE.ERROR,
					message: ERROR_MESSAGES().SERVER_ERROR
				}
			])
		} else if (!axiosError.response?.data?.messages || axiosError.response?.data?.messages.length === 0) {
			// if BE does not send message set general error message
			showNotifications([{ type: MSG_TYPE.ERROR, message: ERROR_MESSAGES().GENERAL_ERROR }])
		} else if (axiosError.response?.status === 401) {
			showNotifications([
				{
					type: MSG_TYPE.ERROR,
					message: ERROR_MESSAGES().UNAUTHORIZED_MSG
				}
			])
		} else {
			axiosError.response?.data.messages.forEach((message) => {
				if (axiosError.config.formErrors) {
					const { setError, errorsMap } = axiosError.config.formErrors
					const formKey = message.path ? findErrorKeyValue(message.path, errorsMap) : undefined
					if (formKey) {
						setError(formKey, { message: message.message })
					} else {
						showNotifications([
							{
								type: MSG_TYPE.ERROR,
								message: message.message
							}
						])
					}
				} else {
					showNotifications([
						{
							type: MSG_TYPE.ERROR,
							message: message.message
						}
					])
				}
			})
		}
	} else {
		showNotifications([
			{
				type: MSG_TYPE.ERROR,
				message: ERROR_MESSAGES().GENERAL_ERROR
			}
		])
	}
}

export const getHomepageHeroSectionData = (assetsPath: string, cmsData: CmsHeroData | undefined): HeroSectionData => {
	const intl = getIntl()

	const title = cmsData?.title || intl.formatMessage({ id: 'hero-section-title' })
	const subtitle = cmsData?.subtitle || intl.formatMessage({ id: 'hero-section-description' })

	let desktopBg = `${assetsPath}/${require('../assets/images/homepage/hero-bg-desktop.jpg')}`
	let mobileBg = `${assetsPath}/${require('../assets/images/homepage/hero-bg-mobile.jpg')}`

	if (cmsData?.background.desktop) {
		desktopBg = cmsData.background.desktop
	}

	if (cmsData?.background.mobile) {
		mobileBg = cmsData.background.mobile
	}

	return { desktopBg, mobileBg, title, subtitle }
}
