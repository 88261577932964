import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { UseFormSetError } from 'react-hook-form'
import { paths as pathsB2c } from '../types/b2c'
import { paths as pathsCms } from '../types/cms'

interface paths extends pathsB2c, pathsCms {}

type FilteredKeys<T, U> = {
	[P in keyof T]: T[P] extends U ? P : never
}[keyof T]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fullFillURL = (urlTemplate: string, params: any) => {
	const pathParams = []
	const queryParams = { ...(params?.query || {}) }
	const fullfilURL = urlTemplate
		.split('/')
		.map((blok) => {
			if (/{[^}]*\}/.test(blok)) {
				const param = blok.replace('{', '').replace('}', '')
				pathParams.push(param)
				delete queryParams[param]
				return (params?.path || {})[param]
			}
			return blok
		})
		.join('/')
	return {
		fullfilURL,
		queryParams
	}
}

type HttpAbortRequest = 'httpGet' | 'httpPost' | 'httpPatch'

/**
 * @type {Map<HttpAbortRequest, Map<string, AbortController>>} abort controllers for http requests
 */
export const abortControllers: Record<HttpAbortRequest, Map<string, AbortController>> = {
	httpGet: new Map<string, AbortController>(),
	httpPost: new Map<string, AbortController>(),
	httpPatch: new Map<string, AbortController>()
}

export enum CANCEL_TOKEN_MESSAGES {
	CANCELED_DUE_TO_NEW_REQUEST = 'Operation canceled due to new request.'
}

/**
 * Builds an abort controller if allowAbort is true, and aborts the previous request if the abortControllerKey exists in the abortControllers map.
 *
 * @param {HttpAbortRequest} requestType - the type of the HTTP request
 * @param {string} abortControllerKey - the key used to identify the abort controller
 * @param {boolean} allowAbort - flag indicating whether to allow aborting
 * @return {object} an object with the signal property pointing to the abort controller's signal, if allowAbort is true; otherwise, an empty object
 */
const buildAbortController = (requestType: HttpAbortRequest, abortControllerKey: string, allowAbort?: boolean) => {
	if (!allowAbort) {
		return undefined
	}

	if (abortControllers[requestType].has(abortControllerKey)) {
		abortControllers[requestType].get(abortControllerKey)?.abort(CANCEL_TOKEN_MESSAGES.CANCELED_DUE_TO_NEW_REQUEST)
	}

	abortControllers[requestType].set(abortControllerKey, new AbortController())

	return abortControllers[requestType].get(abortControllerKey)?.signal
}

export interface ICustomConfig extends AxiosRequestConfig {
	displayNotification?: boolean
	allowAbort?: boolean
	abortSignalKey?: string
	formErrors?: {
		errorsMap: Record<string, string>
		setError: UseFormSetError<any>
	}
}

type RequestSchema = {
	parameters?: object | null
	requestBody?: {
		content: {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			'application/json': any
		}
	}
	responses?: {
		200: {
			content: {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				'application/json': any
			}
		}
	}
}

export type RequestParams<T extends RequestSchema> = T extends {
	parameters: infer Params
}
	? Omit<Params, 'header'>
	: never

export type RequestPayload<T extends RequestSchema> = T extends {
	requestBody?: {
		content: {
			'application/json': infer Payload
		}
	}
}
	? Payload
	: never

export type RequestResponse<T extends RequestSchema> = T extends {
	responses: {
		200: {
			content: {
				'application/json': infer Response
			}
		}
	}
}
	? Response
	: never

type ArgumentsBase<T extends RequestSchema> = {
	params: RequestParams<T>
	reqBody: RequestPayload<T>
	customConfig?: ICustomConfig
}

type RequestArguments<T extends RequestSchema> =
	RequestParams<T> extends never
		? RequestPayload<T> extends never
			? Omit<ArgumentsBase<T>, 'params' | 'reqBody'> | undefined
			: Omit<ArgumentsBase<T>, 'params'>
		: RequestPayload<T> extends never
			? Omit<ArgumentsBase<T>, 'reqBody'>
			: ArgumentsBase<T>

export type GetUrls = {
	[Q in FilteredKeys<paths, { get: RequestSchema }>]: paths[Q]['get']
}

export type PostUrls = {
	[Q in FilteredKeys<paths, { post: RequestSchema }>]: paths[Q]['post']
}

export type PatchUrls = {
	[Q in FilteredKeys<paths, { patch: RequestSchema }>]: paths[Q]['patch']
}

const DEFAULT_CUSTOM_CONFIG: Pick<ICustomConfig, 'displayNotification' | 'abortSignalKey' | 'headers'> = {
	displayNotification: true,
	abortSignalKey: '',
	headers: {}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const prepareRequestConfig = (requestType: HttpAbortRequest, urlTemplate: string, params: any, customConfig?: ICustomConfig) => {
	const { fullfilURL, queryParams } = fullFillURL(urlTemplate, params)

	const config = { ...DEFAULT_CUSTOM_CONFIG, ...customConfig }
	const { abortSignalKey, allowAbort, headers } = config

	const signal = buildAbortController(requestType, abortSignalKey || fullfilURL, allowAbort)

	const requestConfig: ICustomConfig = {
		...config,
		signal,
		headers
	}

	if (queryParams) {
		requestConfig.params = queryParams
	}

	return {
		requestConfig,
		fullfilURL
	}
}

const EMPTY_ARGS = {
	params: undefined,
	customConfig: undefined,
	reqBody: undefined
}

/**
 * Retrieves data from the specified URL using the HTTP GET method.
 *
 * @param {AxiosInstance} axiosClient - Instance of Axios client.
 * @param {T} url - The URL to retrieve data from.
 * @param {RequestArguments<GetUrls[T]>} args - The arguments for the GET request.
 * @return {Promise<AxiosResponse<RequestResponse<GetUrls[T]>>>} A Promise that resolves to the data retrieved from the specified URL.
 */
export const getReq = async <T extends keyof GetUrls>(
	axiosClient: AxiosInstance,
	url: T,
	args: RequestArguments<GetUrls[T]>
): Promise<AxiosResponse<RequestResponse<GetUrls[T]>>> => {
	const { params, customConfig } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpGet', url, params, customConfig)

	const res = await axiosClient.get<RequestResponse<GetUrls[T]>>(fullfilURL, requestConfig)
	return res
}

/**
 * Performs a POST request to the specified URL with the provided parameters and request body.
 *
 * @param {AxiosInstance} axiosClient - Instance of Axios client.
 * @param {string} url - the URL endpoint for the POST request
 * @param {RequestArguments<PostUrls[T]>} args - The arguments for POST request.
 * @return {Promise<AxiosResponse<RequestResponse<PostUrls[T]>>>} A Promise that resolves to the data retrieved from the specified URL.
 */
export const postReq = async <T extends keyof PostUrls>(
	axiosClient: AxiosInstance,
	url: T,
	args: RequestArguments<PostUrls[T]>
): Promise<AxiosResponse<RequestResponse<PostUrls[T]>>> => {
	const { customConfig, params, reqBody } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpPost', url, params, customConfig)

	const res = await axiosClient.post<RequestResponse<PostUrls[T]>>(fullfilURL, reqBody, requestConfig)
	return res
}

/**
 * Performs a PATCH request to the specified URL with the provided parameters and request body.
 *
 * @param {AxiosInstance} axiosClient - Instance of Axios client.
 * @param {string} url - the URL endpoint for the PATCH request
 * @param {RequestArguments<PatchUrls[T]>} args - The arguments for PATCH request.
 * @return {Promise<AxiosResponse<RequestResponse<PatchUrls[T]>>>} A Promise that resolves to the data retrieved from the specified URL.
 */
export const patchReq = async <T extends keyof PatchUrls>(
	axiosClient: AxiosInstance,
	url: T,
	args: RequestArguments<PatchUrls[T]>
): Promise<AxiosResponse<RequestResponse<PatchUrls[T]>>> => {
	const { customConfig, params, reqBody } = { ...EMPTY_ARGS, ...args }
	const { fullfilURL, requestConfig } = prepareRequestConfig('httpPatch', url, params, customConfig)

	const res = await axiosClient.patch<RequestResponse<PatchUrls[T]>>(fullfilURL, reqBody, requestConfig)
	return res
}
