import Axios, { AxiosError, AxiosInstance } from 'axios'

/**
 * Assign interceptors for responses into Axios instance
 * @param {AxiosInstance} axios - Axios instance
 * @param {Function} showErrorNotifications - Callback function for handling errors
 */
export default (axios: AxiosInstance, showErrorNotifications: (error: AxiosError | Error | unknown) => void) => {
	const handleResponseError = (error: AxiosError | Error | any) => {
		if (!Axios.isCancel(error)) {
			showErrorNotifications(error)
		}

		return Promise.reject(error)
	}

	axios.interceptors.response.use((response) => response, handleResponseError)
}
