import React from 'react'

import loadable from '@loadable/component'

import { ContextProps } from './types/types'
import AppProvider from './utils/appProvider'

const LoadableAppLayout = loadable(() => import('./AppLayout'))

export interface IEntryPointProps extends ContextProps {
	fragmentType: string
}

const Entrypoint: React.FC<React.PropsWithChildren<IEntryPointProps>> = (props) => {
	switch (props.fragmentType) {
		case 'appLayout':
		default:
			return (
				<AppProvider {...props}>
					<LoadableAppLayout {...props} />
				</AppProvider>
			)
	}
}

export default Entrypoint
