/* eslint-disable import/no-cycle */
import { AxiosInstance } from 'axios'
import {
	CmsAppFeatureData,
	CmsAppPromoData,
	CmsBannersData,
	CmsDiscoverSalonData,
	CmsDiscoverServiceData,
	CmsHeroData,
	CmsHomepageData,
	CmsNpForBusinessData,
	CmsNpStatisticsData,
	CmsNpTestimonialsData,
	CmsSalonsCategoryPageData,
	CmsSalonsListPageData
} from '../types/types'
import { getReq } from '../utils/request'

const getHeroSection = async (client: AxiosInstance): Promise<CmsHeroData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/hero-section', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			title: data.data.title,
			subtitle: data.data.subtitle,
			background: {
				desktop: data.data.backgroundImage.desktopImage?.url,
				mobile: data.data.backgroundImage.mobileImage?.url
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getDiscoverSalon = async (client: AxiosInstance): Promise<CmsDiscoverSalonData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/discover-salon', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			title: data.data.title,
			subtitle: data.data.subtitle,
			image: {
				desktop: data.data.image.desktopImage?.url,
				mobile: data.data.image.mobileImage?.url,
				alt: data.data.image.alt,
				link: data.data.image.link
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getDiscoverService = async (client: AxiosInstance): Promise<CmsDiscoverServiceData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/discover-service', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			title: data.data.title,
			subtitle: data.data.subtitle
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}
const getTestimonials = async (client: AxiosInstance): Promise<CmsNpTestimonialsData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/testimonials', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			title: data.data.title,
			testimonials: data.data.testimonials || []
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getAppPromo = async (client: AxiosInstance): Promise<CmsAppPromoData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/app-promo', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			title: data.data.title,
			subtitle: data.data.subtitle,
			videoThumbnail: {
				desktop: data.data.videoThumbnail.desktopImage?.url,
				mobile: data.data.videoThumbnail.mobileImage?.url,
				alt: data.data.videoThumbnail.alt,
				link: data.data.videoThumbnail.link
			},
			background: {
				desktop: data.data.backgroundImage.desktopImage?.url,
				mobile: data.data.backgroundImage.mobileImage?.url
			},
			video: data.data.video?.url
				? {
						url: data.data.video.url,
						width: data.data.video.width,
						height: data.data.video.height
					}
				: undefined,
			tooltip: data.data.tooltip
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getAppFeature = async (client: AxiosInstance): Promise<CmsAppFeatureData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/app-feature', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			title: data.data.title,
			subtitle: {
				desktop: data.data.subtitleDesktop,
				mobile: data.data.subtitleMobile
			},
			image1: {
				desktop: data.data.image1.desktopImage?.url,
				mobile: data.data.image1.mobileImage?.url,
				alt: data.data.image1.alt,
				link: data.data.image1.link
			},
			image2: {
				desktop: data.data.image2.desktopImage?.url,
				mobile: data.data.image2.mobileImage?.url,
				alt: data.data.image2.alt,
				link: data.data.image2.link
			},
			image3: {
				desktop: data.data.image3.desktopImage?.url,
				mobile: data.data.image3.mobileImage?.url,
				alt: data.data.image3.alt,
				link: data.data.image3.link
			},
			image4: {
				desktop: data.data.image4.desktopImage?.url,
				mobile: data.data.image4.mobileImage?.url,
				alt: data.data.image4.alt,
				link: data.data.image4.link
			},
			image5: {
				desktop: data.data.image5.desktopImage?.url,
				mobile: data.data.image5.mobileImage?.url,
				alt: data.data.image5.alt,
				link: data.data.image5.link
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getNpForBusiness = async (client: AxiosInstance): Promise<CmsNpForBusinessData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/np-for-business', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			image: {
				desktop: data.data.banner.desktopImage?.url,
				mobile: data.data.banner.mobileImage?.url,
				alt: data.data.banner.alt,
				link: data.data.banner.link
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getNpStatistics = async (client: AxiosInstance): Promise<CmsNpStatisticsData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/np-statistics', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			salonsCount: data.data.salonsCount,
			reservationsCount: data.data.reservationsCount,
			professionalsCount: data.data.professionalsCount
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getBanners = async (client: AxiosInstance): Promise<CmsBannersData | undefined> => {
	try {
		const { data } = await getReq(client, '/b2c/web/cms/banners', { params: {}, reqBody: {}, customConfig: { displayNotification: false } })

		if (!data.data) {
			return undefined
		}

		return {
			banners: data.data.images.reduce<CmsBannersData['banners']>((acc, banner, index) => {
				if (banner.image?.desktopImage?.url && banner.image?.mobileImage?.url) {
					return [
						...acc,
						{
							id: String(index),
							image: {
								desktop: banner.image.desktopImage.url,
								mobile: banner.image.mobileImage.url,
								link: banner.image.link,
								alt: banner.image.alt
							},
							infoText: banner.infoText,
							backgroundColor: banner.backgroundColor,
							backgroundImage: banner.backgroundFile?.url
						}
					]
				}

				return acc
			}, [])
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

const getHomepageCmsData = async (client: AxiosInstance): Promise<CmsHomepageData | undefined> => {
	try {
		const [heroData, discoverSalonData, discoverServiceData, npTestimonialsData, appPromoData, appFeatureData, npForBusinessData, npStatisticsData] =
			await Promise.all([
				getHeroSection(client),
				getDiscoverSalon(client),
				getDiscoverService(client),
				getTestimonials(client),
				getAppPromo(client),
				getAppFeature(client),
				getNpForBusiness(client),
				getNpStatistics(client)
			])

		return {
			heroData,
			discoverSalonData,
			discoverServiceData,
			npTestimonialsData,
			appPromoData,
			appFeatureData,
			npForBusinessData,
			npStatisticsData
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error fetching homepage CMS data:', error)
		return undefined
	}
}

const getSalonsListPageCmsData = async (client: AxiosInstance): Promise<CmsSalonsListPageData | undefined> => {
	try {
		const appPromoData = await getAppPromo(client)

		return {
			appPromoData
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error fetching Salons category page CMS data:', error)
		return undefined
	}
}

const getSalonsCategoryPageCmsData = async (client: AxiosInstance): Promise<CmsSalonsCategoryPageData | undefined> => {
	try {
		const [appPromoData, bannersData] = await Promise.all([getAppPromo(client), getBanners(client)])

		return {
			appPromoData,
			bannersData
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Error fetching Salons category page CMS data:', error)
		return undefined
	}
}

const loadApiEndpoints = (client: AxiosInstance) => {
	return {
		getHomepageData: () => getHomepageCmsData(client),
		getSalonsListPageCmsData: () => getSalonsListPageCmsData(client),
		getSalonsCategoryPageCmsData: () => getSalonsCategoryPageCmsData(client)
	}
}

export default loadApiEndpoints

export type CmsApiEndpoints = ReturnType<typeof loadApiEndpoints>
