import axios, { AxiosInstance } from 'axios'
import initInterceptors from './interceptors'
// eslint-disable-next-line import/no-cycle
import { showErrorNotifications } from './helper'

const createApiClient = (locale: string, apiUrl: string | undefined, appVersion: string, token: string | undefined): AxiosInstance => {
	const client = axios.create({
		baseURL: apiUrl,
		headers: {
			'Accept-Language': locale,
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Access-Control-Allow-Credentials': 'true',
			'Cache-Control': 'no-cache, no-store',
			Pragma: 'no-cache',
			'x-module-wsk-version': appVersion,
			Authorization: token ? `Bearer ${token}` : undefined
		}
	})

	/**
	 * Initialize interceptors for Axios responses (handling errors in catch blocks)
	 * @param {AxiosInstance} client - Axios instance
	 * @param {Function} errorCallback - Callback function for handling errors
	 */
	initInterceptors(client, showErrorNotifications)

	return client
}

export const createBloomreachApiClient = (locale: string, apiUrl: string, token: string | undefined) => {
	const client = axios.create({
		baseURL: apiUrl,
		headers: {
			'Accept-Language': locale,
			'Content-Type': 'application/json',
			Accept: 'application/json',
			'Access-Control-Allow-Credentials': 'true',
			'Cache-Control': 'no-cache, no-store',
			Pragma: 'no-cache',
			'x-user': token
		}
	})

	return client
}

export default createApiClient
