import { PathsType } from '../types/pathType'

const Paths: PathsType = {
	cs: {
		'/salons/homepage': '/salony/homepage/',
		'/salons': '/salony/',
		'/salons/services': '/salony/sluzby/',
		'/salons/services/:categorySlug': '/salony/sluzby/:categorySlug/',
		'/salons/:salonSlug': '/salony/:salonSlug/',
		'/salons/my-reservations': '/salony/moje-rezervace/',
		'/salons/my-reservations/:reservationID': '/salony/moje-rezervace/:reservationID/',
		'/salons/auth-complete': '/salony/auth-complete/'
	},
	sk: {
		'/salons/homepage': '/salony/homepage/',
		'/salons': '/salony/',
		'/salons/services': '/salony/sluzby/',
		'/salons/services/:categorySlug': '/salony/sluzby/:categorySlug/',
		'/salons/:salonSlug': '/salony/:salonSlug/',
		'/salons/my-reservations': '/salony/moje-rezervacie/',
		'/salons/my-reservations/:reservationID': '/salony/moje-rezervacie/:reservationID/',
		'/salons/auth-complete': '/salony/auth-complete/'
	},
	bg: {
		'/salons/homepage': '/saloni/homepage/',
		'/salons': '/saloni/',
		'/salons/services': '/saloni/uslugi/',
		'/salons/services/:categorySlug': '/saloni/uslugi/:categorySlug/',
		'/salons/:salonSlug': '/saloni/:salonSlug/',
		'/salons/my-reservations': '/saloni/moite-rezervi/',
		'/salons/my-reservations/:reservationID': '/saloni/moite-rezervi/:reservationID/',
		'/salons/auth-complete': '/saloni/auth-complete/'
	},
	hu: {
		'/salons/homepage': '/szalonok/homepage/',
		'/salons': '/szalonok/',
		'/salons/services': '/szalonok/szolgaltatasok/',
		'/salons/services/:categorySlug': '/szalonok/szolgaltatasok/:categorySlug/',
		'/salons/:salonSlug': '/szalonok/:salonSlug/',
		'/salons/my-reservations': '/szalonok/fenntartasaim/',
		'/salons/my-reservations/:reservationID': '/szalonok/fenntartasaim/:reservationID/',
		'/salons/auth-complete': '/szalonok/auth-complete/'
	},
	ro: {
		'/salons/homepage': '/saloane/homepage/',
		'/salons': '/saloane/',
		'/salons/services': '/saloane/servicii/',
		'/salons/services/:categorySlug': '/saloane/servicii/:categorySlug/',
		'/salons/:salonSlug': '/saloane/:salonSlug/',
		'/salons/my-reservations': '/saloane/rezervele-mele/',
		'/salons/my-reservations/:reservationID': '/saloane/rezervele-mele/:reservationID/',
		'/salons/auth-complete': '/saloane/auth-complete/'
	}
}

export default Paths
