import React from 'react'

export enum ENVIRONMENTS {
	development = 'development'
}

export enum HOSTS {
	developmentServer = 'developmentServer',
	testServer = 'testServer',
	productionServer = 'productionServer'
}

export enum SALON_TYPE {
	NON_BASIC = 'NON_BASIC',
	BASIC = 'BASIC'
}

export enum DAYS {
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY'
}

export enum OPENING_HOURS_STATUS {
	ALL = 'ALL',
	OPEN = 'OPEN',
	CLOSED = 'CLOSED'
}

export const PDF_FILE_TYPE = 'application/pdf'

const COORDINATES = {
	PRAGUE: {
		lat: 50.08505813401708,
		lng: 14.419970052817515
	},
	BRATISLAVA: {
		lat: 48.152711345286555,
		lng: 17.110079398988983
	},
	BUCHAREST: {
		lat: 44.426765,
		lng: 26.102537
	},
	SOFIA: {
		lat: 42.697708,
		lng: 23.324272
	},
	BUDAPEST: {
		lat: 47.497912,
		lng: 19.040235
	}
}

export const MAP = {
	defaultZoom: 15,
	minLatitude: -90,
	maxLatitude: 90,
	minLongitude: -180,
	maxLongitude: 180,
	minZoom: 1,
	maxZoom: 20,
	placeZoom: 16,
	defaultLocation: COORDINATES.PRAGUE,
	locations: {
		cs: COORDINATES.PRAGUE,
		sk: COORDINATES.BRATISLAVA,
		bg: COORDINATES.SOFIA,
		hu: COORDINATES.BUDAPEST,
		ro: COORDINATES.BUCHAREST
	}
}

export enum SORTING_OPTION {
	RECOMMENDED = 'RECOMMENDED',
	CLOSEST = 'CLOSEST',
	BEST_RATED = 'BEST_RATED',
	CHEAPEST = 'CHEAPEST',
	MOST_EXPENSIVE = 'MOST_EXPENSIVE'
}

export enum OPEN_HOURS_STATE {
	CUSTOM_ORDER = 'CUSTOM_ORDER'
}

export enum SEARCH_FIELD_OPTION_TYPE {
	SALON = 'SALON',
	CITY = 'CITY'
}

export enum PAYMENT_METHOD {
	CARD = 'Card',
	CASH = 'Cash'
}

export const SALONS_SEARCH_DEBOUNCE_TIME_MS = 400
export const SALONS_MAP_DEBOUNCE_TIME_MS = 400
export const COSMETICS_SEARCH_DEBOUNCE_TIME_MS = 400
export const FILTER_COUNTS_CHANGE_DEBOUNCE_TIME_MS = 300
export const CALENDAR_VIEW_CHANGE_DEBOUNCE_TIME_MS = 300

export const GA_EVENTS_DELAY = 100

export enum SALONS_FILTER_TYPE {
	PRICE = 'PRICE',
	AVAILABLE_RS = 'AVAILABLE_RS',
	OPENING_HOURS = 'OPENING_HOURS',
	RATING = 'RATING',
	COSMETICS = 'COSMETICS',
	DATE = 'DATE',
	LANGUAGES = 'LANGUAGES',
	SORTING = 'SORTING'
}

export enum RATINGS {
	ONE = 1,
	TWO = 2,
	THREE = 3,
	FOUR = 4,
	FIVE = 5
}

export enum AVAILABLE_RESERVATION_TYPE {
	PHONE_RESERVATION = 'PHONE_RESERVATION',
	ONLINE_RESERVATION = 'ONLINE_RESERVATION'
}

export enum RESERVATIONS_TIME_SLOTS {
	ANY = 'ANY',
	MORNING = 'MORNING',
	AFTERNOON = 'AFTERNOON',
	EVENING = 'EVENING'
}

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DEFAULT_TIME_FORMAT = 'HH:mm'
export const DAYNAME_DATE_FORMAT = 'dd, D.MMM'
export const DAYNAME_DATE_YEAR_FORMAT = 'dd, D. MMM YY'
export const YEAR_MONTH_FORMAT = 'YYYY-MM'
export const FULL_MONTH_NAME = 'MMMM'

export const ARRAY_QUERY_PARAM_MAX_LENGTH = 50

export enum FORM {
	FILTER_SIDEBAR = 'FILTER_SIDEBAR',
	FILTER_SIDEBAR_PRICE = 'FILTER_SIDEBAR_PRICE',
	FILTER_SIDEBAR_EXACT_RATING = 'FILTER_SIDEBAR_EXACT_RATING',
	FILTER_SIDEBAR_COSMETICS = 'FILTER_SIDEBAR_COSMETICS',
	FILTER_SIDEBAR_LANGUAGES = 'FILTER_SIDEBAR_LANGUAGES',
	FILTER_SIDEBAR_DATE = 'FILTER_SIDEBAR_DATE',
	FILTER_SIDEBAR_ORDER_BY = 'FILTER_SIDEBAR_ORDER_BY',
	BOOKING_FORM = 'BOOKING_FORM',
	RESERVATION_BOOKING_MODAL_SERVICE_PARAMETER_SELECTION = 'RESERVATION_BOOKING_MODAL_SERVICE_PARAMETER_SELECTION',
	RESERVATION_BOOKING_MODAL_EMPLOYEE_AND_DATE_SELECTION = 'RESERVATION_BOOKING_MODAL_EMPLOYEE_AND_DATE_SELECTION',
	RESERVATION_BOOKING_MODAL_BOOKING_CONFIRMATION = 'RESERVATION_BOOKING_MODAL_BOOKING_CONFIRMATION'
}

export enum SELECT_OPTION_CLASS_NAME {
	BOTTOM_DIVIDER = 'select-option-with-bottom-divider'
}

export enum RESERVATION_BOOKING_MODAL_STEP {
	SERVICE_SELECTION = 'SERVICE_SELECTION',
	PROMO_BANNER = 'PROMO_BANNER',
	SERVICE_PARAMETER_SELECTION = 'SERVICE_PARAMETER_SELECTION',
	EMPLOYEE_AND_DATE_SELECTION = 'EMPLOYEE_AND_DATE_SELECTION',
	BOOKING_CONFIRMATION = 'BOOKING_CONFIRMATION',
	SUMMARY = 'SUMMARY'
}

export const APP_CONTAINER_ID = 'NOTINO_SALONS_PAGE_APP_CONTAINER'

export enum DOCUMENTS {
	B2C_PRIVACY_POLICY = 'B2C_PRIVACY_POLICY',
	B2C_RESERVATIONS_TERMS = 'B2C_RESERVATIONS_TERMS',
	B2C_PLACEHOLDER_HOMESCREEN = 'B2C_PLACEHOLDER_HOMESCREEN',
	B2C_NOTINO_RECEPTION_DASHBOARD = 'B2C_NOTINO_RECEPTION_DASHBOARD',
	B2C_NOTINO_RECEPTION_SALON_DETAIL = 'B2C_NOTINO_RECEPTION_SALON_DETAIL'
}

export const RESERVATION_BOOKING_MODAL_FIXED_HEADER_HEIGHT = 72 // in px

export enum VALIDATION_MAX_LENGTH {
	LENGTH_1500 = 1500,
	LENGTH_500 = 500,
	LENGTH_255 = 255,
	LENGTH_160 = 160,
	LENGTH_20 = 20
}

export const SESSION_STORAGE_KEYS = {
	IS_SALONS_SEARCH: 'IS_SALONS_SEARCH'
}

export enum PARTIAL_RATING {
	SERVICE = 'SERVICE',
	PLACE = 'PLACE',
	COMMUNICATION = 'COMMUNICATION',
	GENERAL_IMPRESSION = 'GENERAL_IMPRESSION'
}

export enum SCREEN_STATE_KEY {
	RESERVATION_SUMMARY_MODAL_SALON_CATEGORY_DETAIL = 'RESERVATION_SUMMARY_MODAL_SALON_CATEGORY_DETAIL'
}
export enum RESERVATION_STATE {
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	DECLINED = 'DECLINED',
	CANCEL_BY_SALON = 'CANCEL_BY_SALON',
	CANCEL_BY_CUSTOMER = 'CANCEL_BY_CUSTOMER',
	CANCEL_BY_SYSTEM = 'CANCEL_BY_SYSTEM',
	REALIZED = 'REALIZED',
	NOT_REALIZED = 'NOT_REALIZED'
}

export const FUTURE_RESERVATIONS_STATES = [RESERVATION_STATE.PENDING, RESERVATION_STATE.APPROVED]

export const PAST_RESERVATIONS_STATES = [
	RESERVATION_STATE.DECLINED,
	RESERVATION_STATE.CANCEL_BY_SALON,
	RESERVATION_STATE.CANCEL_BY_CUSTOMER,
	RESERVATION_STATE.REALIZED,
	RESERVATION_STATE.NOT_REALIZED
]

export const RESERVATIONS_LIMIT_SALONS_PAGE = 25
export const FUTURE_RESERVATIONS_LIMIT_MY_RESERVATIONS_PAGE = 100
export const PAST_RESERVATIONS_LIMIT_MY_RESERVATIONS_PAGE = 12

export enum TOKEN_AUD {
	REVIEW_RESERVATION = 'REVIEW_RESERVATION'
}

export const SALONS_PAGE_BANNER_SETTINGS = {
	DEFAULT_BACKGROUND_COLOR: '8695CA', // must be without '#', because <FullWidthBanner> component expects color without it
	DESKTOP_BANNER_WIDTH: 1035, // px
	DESKTOP_BANNER_HEIGHT: 340, // px
	MOBILE_BANNER_WIDTH: 768, // px
	MOBILE_BANNER_HEIGHT: 340 // px
}

export const GOOGLE_MAPS_URL = 'https://maps.google.com'

export enum MSG_TYPE {
	INFO = 'info',
	ERROR = 'error'
	// WARNING = 'warning',
	// SUCCESS = 'success'
}

/*
We display salons in 3 or 2 or 1 column grid,
so we need number divisible by 3 a 2 in order to
avoid "missing" salons on the end of the grid -
base number is thus 12.
Update: we need to show banner at 4th place in grid,
that's why we need to fetch only 11 salons per page.
*/
export const SALONS_GRID_ELEMENTS_PER_PAGE = 12
const SALONS_BANNERS_ELEMENTS_PER_PAGE = 1
export const SALONS_PER_PAGE = SALONS_GRID_ELEMENTS_PER_PAGE - SALONS_BANNERS_ELEMENTS_PER_PAGE

export type ErrorMessage = {
	type: MSG_TYPE
	message: React.ReactNode
	path?: string
}

export const ABORT_KEYS = {
	AVAILABLE_SLOT_REQUEST_1_ABORT_KEY: 'AVAILABLE_SLOT_REQUEST_1_ABORT_KEY',
	AVAILABLE_SLOT_REQUEST_2_ABORT_KEY: 'AVAILABLE_SLOT_REQUEST_2_ABORT_KEY',
	SALONS_ABORT_KEY: 'SALONS_ABORT_KEY',
	SALONS_MAP_ABORT_KEY: 'SALONS_MAP_ABORT_KEY',
	SALONS_FILTER_COUNT_ABORT_KEY: 'SALONS_FILTER_COUNT_ABORT_KEY'
}

export enum PRODUCTION_ENVIRONMENT {
	PRODUCTION = 'production',
	UAT = 'uat',
	TEST = 'test',
	DEVELOPMENT = 'development',
	LOCAL = 'local'
}
