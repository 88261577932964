import React, { useMemo, createContext } from 'react'

// api
import loadB2cApiEndpoints, { B2cApiEndpoint } from '../api/b2c'
import loadCmsApiEndpoints, { CmsApiEndpoints } from '../api/cms'
import loadBloomreachApiEndpoints, { BloomreachApiEndpoints } from '../api/bloomreach'

// utils
import createApiClient, { createBloomreachApiClient } from './axiosClientConfig'
import { ContextProps } from '../types/types'
import { DEFAULT_SHOP_ID } from '../appDefaults'
import { PRODUCTION_ENVIRONMENT } from './enums'

type Props = ContextProps

type ApiEndpoints = {
	b2c: B2cApiEndpoint
	cms: CmsApiEndpoints
	bloomreach: BloomreachApiEndpoints
}

type AppContextModel = {
	apiBrowser: ApiEndpoints
} & Omit<ContextProps, 'api'>

export const AppContext = createContext<AppContextModel>({
	apiBrowser: {} as ApiEndpoints,
	assetsPath: '',
	googleMapsApiKey: '',
	userToken: undefined,
	locale: '',
	shopId: DEFAULT_SHOP_ID,
	apiUrl: '',
	appVersion: '',
	isAuthorized: false,
	sentryOptions: {
		isEnabled: false,
		environment: '',
		dsn: ''
	},
	cms: {
		url: ''
	},
	bloomreach: {
		url: ''
	},
	userInfo: {},
	productionEnvironment: PRODUCTION_ENVIRONMENT.PRODUCTION
})

/* Create api client for requests outside of server -> on client browser. If you want use this request you need to add your fetch function to return statement. */
const createBrowserApiClient = (props: Props): ApiEndpoints => {
	const { locale, apiUrl, appVersion, userToken, cms, bloomreach } = props
	// create axios instance
	const client = createApiClient(locale, apiUrl, appVersion, userToken)
	const cmsApiClient = createApiClient(locale, cms.url, appVersion, undefined)

	const bloomreachApiClient = createBloomreachApiClient(locale, bloomreach.url, userToken)

	return {
		b2c: {
			...loadB2cApiEndpoints(client)
		},
		cms: {
			...loadCmsApiEndpoints(cmsApiClient)
		},
		bloomreach: {
			...loadBloomreachApiEndpoints(bloomreachApiClient)
		}
	}
}

const AppProvider: React.FC<React.PropsWithChildren<Props>> = (props) => {
	const value = useMemo(
		() => ({
			apiBrowser: createBrowserApiClient(props),
			...props
		}),
		[props]
	)

	return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
}

export default AppProvider
