/* eslint-disable import/no-cycle */
import { AxiosInstance } from 'axios'
import {
	GetAvailableRsTimeSlotsParams,
	GetCosmeticsDataParams,
	GetSalonReviewsDataParams,
	GetSalonsDataForMapParams,
	GetSalonsDataParams,
	GetSalonsFilterCountsParams,
	AvailableRsTimeSlotsResponse,
	CategoriesResponse,
	CategoryResponse,
	ConfigResponse,
	CosmeticsResponse,
	GetSalonsFilterCountResponse,
	LanguagesResponse,
	MapPointsResponse,
	SalonResponse,
	SalonReviewsResponse,
	SalonServicesResponse,
	SalonsFilterResponse,
	SalonsResponse,
	CityByPlaceId,
	GetSalonFiltersQueryParams,
	PostCalendarEventsReservationBody,
	PostCalendarEventsReservationResponse,
	SalonServiceResponse,
	GetCalendarEventsReservationAvailableParams,
	GetCalendarEventsReservationAvailableResponse,
	GetCalendarEventsReservationFirstAvailableDateResponse,
	GetCalendarEventsReservationFirstAvailableDateParams,
	GetDocumentsDateParams,
	DocumentsResponse,
	PostSalonReviewRequestBody,
	PostSalonReviewResponse,
	GetFutureReservationsParams,
	FutureReservationsResponse,
	GetPastReservationsParams,
	PastReservationsResponse,
	GetReservationParams,
	ReservationResponse,
	PatchCancelCalendarEventsReservationBody,
	PatchCancelCalendarEventsReservationResponse
} from '../types/types'
import { SALON_TYPE, SALONS_PER_PAGE, SORTING_OPTION } from '../utils/enums'
import { getReq, ICustomConfig, patchReq, postReq } from '../utils/request'

const getSalonsDataParamsForRequest = (query: GetSalonsDataParams) => {
	const {
		page,
		limit = SALONS_PER_PAGE,
		openingHoursStatus,
		latMy,
		lonMy,
		lat,
		lon,
		categoryIDs,
		orderBy,
		exactRating,
		languageIDs,
		cosmeticIDs,
		hasAvailableReservationSystem: hasAvailableReservationSystemParam,
		serviceTotalPriceTo,
		serviceTotalPriceFrom,
		serviceTotalPriceCurrencyCode,
		avResTimeSlotDayPart,
		avResTimeSlotDateFrom: avResTimeSlotDateFromParam,
		avResTimeSlotDateTo: avResTimeSlotDateToParam
	} = query

	let createType: SALON_TYPE | undefined
	let order: string | undefined
	let hasAvailableReservationSystem: boolean | undefined = hasAvailableReservationSystemParam || undefined

	if (!orderBy || orderBy === SORTING_OPTION.RECOMMENDED) {
		createType = SALON_TYPE.NON_BASIC
		order = 'distance:asc'
		hasAvailableReservationSystem = true
	} else if (orderBy === SORTING_OPTION.CLOSEST) {
		order = 'distance:asc'
	} else if (orderBy === SORTING_OPTION.BEST_RATED) {
		order = 'rating:desc'
	} else if (orderBy === SORTING_OPTION.CHEAPEST) {
		order = 'priceLevel:asc'
	} else if (orderBy === SORTING_OPTION.MOST_EXPENSIVE) {
		order = 'priceLevel:desc'
	}

	let avResTimeSlotDate
	let avResTimeSlotDateFrom
	let avResTimeSlotDateTo

	// NOTE: If only one date is set and not a date range, it should be sent as avResTimeSlotDate to avoid backend errors.
	if (avResTimeSlotDateFromParam && avResTimeSlotDateToParam) {
		avResTimeSlotDateFrom = avResTimeSlotDateFromParam
		avResTimeSlotDateTo = avResTimeSlotDateToParam
	} else if (avResTimeSlotDateFromParam) {
		avResTimeSlotDate = avResTimeSlotDateFromParam
	}

	const queryParams = {
		page,
		openingHoursStatus,
		latMy,
		lonMy,
		lat,
		lon,
		categoryIDs,
		createType,
		order,
		exactRating,
		limit,
		hasAvailableReservationSystem,
		serviceTotalPriceTo,
		serviceTotalPriceFrom,
		serviceTotalPriceCurrencyCode,
		languageIDs,
		cosmeticIDs,
		avResTimeSlotDayPart,
		avResTimeSlotDate,
		avResTimeSlotDateFrom,
		avResTimeSlotDateTo
	}
	return queryParams
}

const getSalonsData = async (query: GetSalonsDataParams, client: AxiosInstance, customConfig?: ICustomConfig): Promise<SalonsResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/', { params: { query: getSalonsDataParamsForRequest(query) }, reqBody: {}, customConfig })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getSalonsFilterCounts = async (
	query: GetSalonsFilterCountsParams,
	client: AxiosInstance,
	customConfig?: ICustomConfig
): Promise<GetSalonsFilterCountResponse> => {
	const { serviceTotalPriceCurrencyCode } = query
	const {
		openingHoursStatus,
		latMy,
		lat,
		lon,
		lonMy,
		categoryIDs,
		createType,
		exactRating,
		hasAvailableReservationSystem,
		serviceTotalPriceTo,
		serviceTotalPriceFrom,
		languageIDs,
		cosmeticIDs,
		avResTimeSlotDayPart,
		avResTimeSlotDate,
		avResTimeSlotDateFrom,
		avResTimeSlotDateTo
	} = getSalonsDataParamsForRequest(query)

	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/filter/counts', {
			params: {
				query: {
					openingHoursStatus,
					lat,
					lon,
					latMy,
					lonMy,
					categoryIDs,
					createType,
					exactRating,
					hasAvailableReservationSystem,
					serviceTotalPriceTo,
					serviceTotalPriceFrom,
					serviceTotalPriceCurrencyCode,
					languageIDs,
					cosmeticIDs,
					avResTimeSlotDayPart,
					avResTimeSlotDate,
					avResTimeSlotDateFrom,
					avResTimeSlotDateTo
				}
			},
			reqBody: {},
			customConfig
		})

		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

const getSalonsDataForMap = async (
	query: GetSalonsDataForMapParams,
	client: AxiosInstance,
	customConfig?: ICustomConfig
): Promise<MapPointsResponse | undefined> => {
	const {
		openingHoursStatus,
		latMy,
		lonMy,
		categoryIDs,
		createType,
		exactRating,
		hasAvailableReservationSystem,
		serviceTotalPriceTo,
		serviceTotalPriceFrom,
		serviceTotalPriceCurrencyCode,
		languageIDs,
		cosmeticIDs,
		avResTimeSlotDayPart,
		avResTimeSlotDate,
		avResTimeSlotDateFrom,
		avResTimeSlotDateTo
	} = getSalonsDataParamsForRequest(query)

	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/map', {
			params: {
				query: {
					openingHoursStatus,
					categoryIDs,
					exactRating,
					createType,
					hasAvailableReservationSystem,
					serviceTotalPriceFrom,
					serviceTotalPriceTo,
					serviceTotalPriceCurrencyCode,
					languageIDs,
					cosmeticIDs,
					avResTimeSlotDayPart,
					avResTimeSlotDate,
					avResTimeSlotDateFrom,
					avResTimeSlotDateTo,
					latMy,
					lonMy,
					latNW: query.latNW,
					lonNW: query.lonNW,
					latSE: query.latSE,
					lonSE: query.lonSE
				}
			},
			reqBody: {},
			customConfig
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

const getSalonsFilterData = async (query: GetSalonFiltersQueryParams, client: AxiosInstance): Promise<SalonsFilterResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/filter/', { params: { query }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

const getSalonData = async (seoSlugName: string, client: AxiosInstance, customConfig?: ICustomConfig): Promise<SalonResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/by-seo-slugname/{seoSlugName}', {
			params: { path: { seoSlugName } },
			reqBody: {},
			customConfig
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

const getSalonServicesData = async (salonID: string, client: AxiosInstance): Promise<SalonServicesResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/{salonID}/services', { params: { path: { salonID } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

const REVIEWS_LIMIT = 3
const getSalonReviewsData = async (salonID: string, params: GetSalonReviewsDataParams, client: AxiosInstance): Promise<SalonReviewsResponse> => {
	try {
		const queryParams = {
			page: params.page,
			limit: REVIEWS_LIMIT
		}
		const { data } = await getReq(client, '/api/b2c/web/salons/{salonID}/reviews/', { params: { query: queryParams, path: { salonID } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getCategoriesData = async (client: AxiosInstance): Promise<CategoriesResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/enums/categories/', { params: {}, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getCategoryData = async (categoryID: string, client: AxiosInstance): Promise<CategoryResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/enums/categories/{categoryID}', { params: { path: { categoryID } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getConfigData = async (client: AxiosInstance): Promise<ConfigResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/config/', { params: {}, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getCosmeticsData = async (query: GetCosmeticsDataParams, client: AxiosInstance): Promise<CosmeticsResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/enums/cosmetics/', { params: { query }, reqBody: {}, customConfig: { allowAbort: true } })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getLanguagesData = async (client: AxiosInstance): Promise<LanguagesResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/enums/languages/', { params: {}, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getAvailableReservationTimeSlotsData = async (
	query: GetAvailableRsTimeSlotsParams,
	client: AxiosInstance
): Promise<AvailableRsTimeSlotsResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/filter/available-reservation-time-slots', { params: { query }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getCityNameByPlaceId = async (placeId: string, client: AxiosInstance): Promise<CityByPlaceId | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/filter/cities/{placeID}', { params: { path: { placeID: placeId } }, reqBody: {} })
		return data.city
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const postCalendarEventsReservation = async (
	salonID: string,
	reqBody: PostCalendarEventsReservationBody,
	client: AxiosInstance,
	customConfig?: ICustomConfig
): Promise<PostCalendarEventsReservationResponse> => {
	try {
		const { data } = await postReq(client, '/api/b2c/web/salons/{salonID}/calendar-events/reservations/', {
			params: { path: { salonID } },
			reqBody,
			customConfig
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

const getSalonServiceData = async (serviceID: string, client: AxiosInstance): Promise<SalonServiceResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/services/{serviceID}', { params: { path: { serviceID } }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getCalendarEventsReservationAvailableParamsSlots = async (
	salonID: string,
	query: GetCalendarEventsReservationAvailableParams,
	client: AxiosInstance,
	customConfig?: ICustomConfig
): Promise<GetCalendarEventsReservationAvailableResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/{salonID}/calendar-events/reservations/available', {
			params: {
				path: { salonID },
				query
			},
			reqBody: {},
			customConfig
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getCalendarEventsReservationFirstAvailableDate = async (
	salonID: string,
	query: GetCalendarEventsReservationFirstAvailableDateParams,
	client: AxiosInstance,
	customConfig?: ICustomConfig
): Promise<GetCalendarEventsReservationFirstAvailableDateResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/salons/{salonID}/calendar-events/reservations/first-available-date', {
			params: {
				path: { salonID },
				query
			},
			reqBody: {},
			customConfig
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const getDocuments = async (
	client: AxiosInstance,
	query: GetDocumentsDateParams,
	customConfig?: ICustomConfig
): Promise<DocumentsResponse | undefined> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/documents/', { params: { query }, reqBody: {}, customConfig })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return undefined
	}
}

export const postSalonReview = async (
	salonID: string,
	reqBody: PostSalonReviewRequestBody,
	client: AxiosInstance,
	customConfig?: ICustomConfig
): Promise<PostSalonReviewResponse> => {
	try {
		const { data } = await postReq(client, '/api/b2c/web/salons/{salonID}/reviews/', {
			params: { path: { salonID } },
			reqBody,
			customConfig
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getFutureReservations = async (client: AxiosInstance, query: GetFutureReservationsParams): Promise<FutureReservationsResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/calendar-events/reservations/future', { params: { query }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const getPastReservations = async (client: AxiosInstance, query: GetPastReservationsParams): Promise<PastReservationsResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/calendar-events/reservations/past', { params: { query }, reqBody: {} })
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

const getReservationData = async (client: AxiosInstance, reservationID: string, query?: GetReservationParams): Promise<ReservationResponse> => {
	try {
		const { data } = await getReq(client, '/api/b2c/web/calendar-events/reservations/{calendarEventID}', {
			params: { path: { calendarEventID: reservationID }, query },
			reqBody: {}
		})

		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

export const patchCalendarEventsReservationCancel = async (
	reservationID: string,
	reqBody: PatchCancelCalendarEventsReservationBody,
	client: AxiosInstance,
	customConfig?: ICustomConfig
): Promise<PatchCancelCalendarEventsReservationResponse> => {
	try {
		const { data } = await patchReq(client, '/api/b2c/web/calendar-events/reservations/{calendarEventID}/cancel', {
			params: { path: { calendarEventID: reservationID } },
			reqBody,
			customConfig
		})
		return data
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
}

const loadApiEndpoints = (client: AxiosInstance) => {
	return {
		// Get requests
		getSalonData: (seoSlugName: string, customConfig?: ICustomConfig) => getSalonData(seoSlugName, client, customConfig),
		getSalonsDataForMap: (params: GetSalonsDataForMapParams, customConfig?: ICustomConfig) => getSalonsDataForMap(params, client, customConfig),
		getSalonsFilterCounts: (params: GetSalonsFilterCountsParams, customConfig?: ICustomConfig) => getSalonsFilterCounts(params, client, customConfig),
		getSalonServicesData: (salonID: string) => getSalonServicesData(salonID, client),
		getSalonServiceData: (serviceID: string) => getSalonServiceData(serviceID, client),
		getSalonReviewsData: (salonID: string, params: GetSalonReviewsDataParams) => getSalonReviewsData(salonID, params, client),
		getSalonsData: (params: GetSalonsDataParams, customConfig?: ICustomConfig) => getSalonsData(params, client, customConfig),
		getCategoriesData: () => getCategoriesData(client),
		getCategoryData: (categoryID: string) => getCategoryData(categoryID, client),
		getSalonsFilterData: (query: GetSalonFiltersQueryParams) => getSalonsFilterData(query, client),
		getConfigData: () => getConfigData(client),
		getCosmeticsData: (params: GetCosmeticsDataParams) => getCosmeticsData(params, client),
		getLanguagesData: () => getLanguagesData(client),
		getAvailableReservationTimeSlotsData: (params: GetAvailableRsTimeSlotsParams) => getAvailableReservationTimeSlotsData(params, client),
		getCityNameByPlaceId: (placeId: string) => getCityNameByPlaceId(placeId, client),
		getCalendarEventsReservationAvailableParamsSlots: (salonID: string, query: GetCalendarEventsReservationAvailableParams, customConfig?: ICustomConfig) =>
			getCalendarEventsReservationAvailableParamsSlots(salonID, query, client, customConfig),
		getCalendarEventsReservationFirstAvailableDate: (
			salonID: string,
			query: GetCalendarEventsReservationFirstAvailableDateParams,
			customConfig?: ICustomConfig
		) => getCalendarEventsReservationFirstAvailableDate(salonID, query, client, customConfig),
		getDocuments: (query: GetDocumentsDateParams, customConfig?: ICustomConfig) => getDocuments(client, query, customConfig),
		getFutureReservations: (query: GetFutureReservationsParams) => getFutureReservations(client, query),
		getPastReservations: (query: GetPastReservationsParams) => getPastReservations(client, query),
		getReservationData: (reservationID: string, query?: GetReservationParams) => getReservationData(client, reservationID, query),
		// Post requests
		postCalendarEventsReservation: (salonID: string, reqBody: PostCalendarEventsReservationBody, customConfig?: ICustomConfig) =>
			postCalendarEventsReservation(salonID, reqBody, client, customConfig),
		postSalonReview: (salonID: string, reqBody: PostSalonReviewRequestBody, customConfig?: ICustomConfig) =>
			postSalonReview(salonID, reqBody, client, customConfig),
		// patch requests
		patchCalendarEventsReservationCancel: (reservationID: string, reqBody: PatchCancelCalendarEventsReservationBody, customConfig?: ICustomConfig) =>
			patchCalendarEventsReservationCancel(reservationID, reqBody, client, customConfig)
	}
}

export default loadApiEndpoints

export type B2cApiEndpoint = ReturnType<typeof loadApiEndpoints>
