import { IntlShape } from 'react-intl'

let intl: IntlShape

export const setIntl = (intlInstance: IntlShape) => {
	intl = intlInstance
}

export const getIntl = (): IntlShape => {
	if (!intl) {
		throw new Error('Intl object is not initialized yet.')
	}
	return intl
}
