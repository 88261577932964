import { PRODUCTION_ENVIRONMENT } from './utils/enums'

export const DEFAULT_SHOP_ID = 'notino.cz'
export const DEFAULT_LANG_TAG = 'cs-CZ'
export const DEFAULT_LOCALE = 'cs'

// NOTE: when adding new language, add it to AVAILABLE_LANGUAGES and shopsConfig
export const AVAILABLE_LANGUAGES = ['cs', 'sk', 'bg', 'hu', 'ro'] as const

export const shopsConfig = [
	{ url: `https://www.notino.cz`, locale: DEFAULT_LOCALE, lang: DEFAULT_LANG_TAG },
	{ url: `https://www.notino.sk`, locale: 'sk', lang: 'sk-SK' },
	{ url: `https://www.notino.bg`, locale: 'bg', lang: 'bg-BG' },
	{ url: `https://www.notino.hu`, locale: 'hu', lang: 'hu-HU' },
	{ url: `https://www.notino.ro`, locale: 'ro', lang: 'ro-RO' }
]

export const getEnvironmentSpecificShopsConfig = (productionEnvironment: PRODUCTION_ENVIRONMENT, locale: string) => {
	let config = [...shopsConfig]

	switch (productionEnvironment) {
		case PRODUCTION_ENVIRONMENT.TEST:
		case PRODUCTION_ENVIRONMENT.DEVELOPMENT:
		case PRODUCTION_ENVIRONMENT.LOCAL: {
			config = [
				{ url: 'https://cz.dev.notino.app', locale: DEFAULT_LOCALE, lang: DEFAULT_LANG_TAG },
				{ url: 'https://sk.dev.notino.app', locale: 'sk', lang: 'sk-SK' },
				{ url: 'https://bg.dev.notino.app', locale: 'bg', lang: 'bg-BG' },
				{ url: 'https://hu.dev.notino.app', locale: 'hu', lang: 'hu-HU' },
				{ url: 'https://ro.dev.notino.app', locale: 'ro', lang: 'ro-RO' }
			]
			break
		}
		case PRODUCTION_ENVIRONMENT.UAT:
			config = [
				{ url: 'https://cz.stg.notino.app', locale: DEFAULT_LOCALE, lang: DEFAULT_LANG_TAG },
				{ url: 'https://sk.stg.notino.app', locale: 'sk', lang: 'sk-SK' },
				{ url: 'https://bg.stg.notino.app', locale: 'bg', lang: 'bg-BG' },
				{ url: 'https://hu.stg.notino.app', locale: 'hu', lang: 'hu-HU' },
				{ url: 'https://ro.stg.notino.app', locale: 'ro', lang: 'ro-RO' }
			]
			break
		default:
			break
	}
	return config.find((shop) => shop.locale === locale || shop.lang === locale) || config[0]
}
